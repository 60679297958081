<template>
	<div class="home">
		
		<div class="main">
			<div class="home_head">
				<div class="home_change" @click="showStudio=true">
					<div class="hc_left">
						<span>{{studioInfo.name}}</span>
					</div>
					<div class="hc_right">
						<i class="iconfont icon-qiehuanchengshi"></i>
						<span>切换</span>
					</div>
				</div>
			</div>
			<div class="home_main">
				<div class="hm_total">
					<div class="hmt_head">
						<template v-for="item in roomList">
							<div class="hmth_div" :class="{selecthmth:active==item.room_id}" :key="item.room_id" @click="setActive(item)">
								<span>{{item.room_name}}</span>
								<div class="hmth_xian" v-if="active==item.room_id"></div>
							</div>
						</template>
						<!-- <div class="hmth_div" :class="{selecthmth:active==1}">
							<span>灭活苗生产车间</span>
							<div class="hmth_xian" v-if="active==1"></div>
						</div> -->
					</div>
					<div class="hmt_list">
						<template v-for="item in nowData.devices">
							<div class="hmtl_div" :key="item.type" @click="toDeviceList(item)">
								<div class="hmtld_top" >
									<span>{{item.count}}</span>
									<span style="font-size:0.32rem;color:#999999;margin-left:16px">台</span>
									<i class="iconfont icon-gengduo"></i>
								</div>
								<div class="hmtld_name">
									<span>{{item.name}}</span>
								</div>
							</div>
						</template>
						
						
					</div>
				</div>

				<div class="hm_warning">
					<div class="hmw_title">
						<span>当前异常({{warningList.length}})</span>
					</div>
					<div class="hmw_list" v-if="warningList.length>0">
						<template v-for="item in warningList">
							<div class="hmwl_div" :key="item.id">
								<div class="hmwld_top">
									<div class="hmwldt_left">
										<div class="hl_circle" v-if="item.warning_type=='high'" style="background:#FFE9E8">
											<i style="color:#FF5A4E" class="iconfont icon-nav_icon_bjgl_spe"></i>
										</div>
										<div class="hl_circle" v-if="item.warning_type=='low'" style="background:#E2ECFF">
											<i style="color:#4E89FF" class="iconfont icon-nav_icon_bjgl_spe"></i>
										</div>
										<div class="hl_name"  v-if="item.warning_type=='high'">
											<span>房间内{{item.property_name}}过高</span>
											<span>超过{{item.property_name}}报警上限值</span>
										</div>
										<div class="hl_name"  v-if="item.warning_type=='low'">
											<span>房间内{{item.property_name}}过低</span>
											<span style="color:#4E89FF">低于{{item.property_name}}报警下限值</span>
										</div>
									</div>
									
								</div>
								<div class="hwmld_bottom">
									<span>所在房间：{{item.workshop_name}}-{{item.work_room_name}}</span>
								</div>
							</div>
						</template>

						
						
					</div>

					<div class="nodata_view" v-if="warningList.length==0">
						<img src="/static/imgs/no_data.jpg">
						<div class="nodata_txt">
							<span>暂无异常</span>
						</div>
					</div>
				</div>
			</div>

		</div>

		<van-popup v-model="showStudio" round position="bottom" closeable>
			<div class="switchTitle">切换洁净室</div>
			<div class="switchMain" v-for="(item, index) in studioList" :key="index" :class="studioInfo.id == item.id && userInfo.user_id == item.user_id ? 'switchYes' : 'switchMain'" @click="toSwitch(item)">
				<div class="switchLeft">
					
				</div>
				<div class="switchRight">
					<div class="switchName">{{ item.name }}</div>
					<div class="switchUserName">{{ item.user_name }}</div>
				</div>
			</div>
		</van-popup>
		<footer-main active="/"></footer-main>
	</div>
</template>

<script>


import studio_login_api from '@/api/teaching_studio_no_login.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import until from '@/api/until.js'
import footerMain from '@/components/footer.vue'
import studio_tool_api from '@/api/teaching_studio_tool.js'
export default {
	name: 'Home',
	data() {
		return {
			active:0,
			roomList:[],
			nowData:{},
			warningList:[],

			salt:"",

			studioList:[],
			showStudio:false,

			studioInfo:{},
			userInfo:{},
		}
	},
	components: {
		footerMain

	},
	mounted() {
		document.title = "智慧生产车间"
	
		this.getHomeData();
		this.getWarningList();
		// this.getSalt();
		this.getMyRoomList()
		this.getStudioInfo();
		this.getUserInfo()
	
		this.$EventBus.$on("login_success", () => {
			this.getHomeData();
			this.getWarningList();
			this.getMyRoomList()
			this.getUserInfo()
		})
		
	},
	methods: {
		toDeviceList(item){
			this.$router.push("/devicelist?type="+item.type+"&work_shop_id="+this.nowData.work_shop_id)
		},

		//获取我的洁净室
		async getMyRoomList(){
			let app_id = this.$root.app_id
			let data = await studio_api.Teaching_studio_apiStudioWechatStudioGet({ app_id });
			if (data.errcode == 0) {
				this.studioList = data.other
			}

		},

		toSwitch(item) {
			this.switchStudio(item)
		},
		async switchStudio(item) {
			let app_id = this.$root.app_id
			let to_studio_id = item.id
			let to_user_id = item.user_id
			this.$toast.loading({
				message: "正在切换...",
				forbidClick: true,
				duration: 0,
			});
			let data = await studio_api.Teaching_studio_apiStudioToStudioGet({ app_id, to_studio_id, to_user_id });
			if (data.errcode == 0) {
				this.showStudio = false
				if(data.other){
					window.location.href=data.other
				}
				else{
					window.location.reload()
				}
					
				//window.location.reload()
				
				this.$toast.clear()
			}
		},

			//切换洁净室

		//获取当前登录的用户
		async getUserInfo() {
			let app_id = this.$root.app_id;
			let data = await studio_api.Teaching_studio_apiMemberLogin_userGet({ app_id });
			if (data.errcode == 0) {
				this.userInfo = data.other;
				
			}
		},


	


		//获取简介
		async getStudioInfo() {
			let id = this.$root.id;
			let app_id = this.$root.app_id
			let data = await studio_login_api.Api_nologinStudio_openInfoGet({ id, app_id });
			if (data.errcode == 0) {
				if (data.other.id) {

					this.studioInfo = data.other;
					
					document.title = data.other.name
				}
				else {
					this.no_data = true;
				}
			}
		},
		


		//获取首页数据
		async getHomeData(){
			let app_id=this.$root.app_id
			let data=await studio_api.Teaching_studio_apiWorkshopDeviceCountGet({app_id});
			if(data.errcode==0){
				this.roomList=data.other;
				if(data.other.length>0){
					this.active=data.other[0].room_id
					this.nowData=data.other[0]
				}
			}
		},
		//获取当前首页报警
		async getWarningList(){
			let app_id=this.$root.app_id
			let data=await studio_api.Teaching_studio_apiIot_device_propertyStudioWarningsGet({app_id})
			if(data.errcode==0){
				this.warningList=data.other;
			}
		},

		setActive(item){
			this.active=item.room_id
			this.nowData=item;

			
		},

		//获取salt
		//获取salt
		async getSalt(){
			let computer_code=until.getCookie("guid").substring(0, 6)
			let data=await studio_tool_api.ToolForm_saltGet({computer_code});
			if(data.errcode==0){
				this.salt=data.other;
				// this.sendMen();
			}
		},

		// //发布菜单到公众号
		// async sendMen(){
		// 	let app_id=this.$root.app_id;
		// 	let id=2
		// 	let data=await studio_api.Teaching_studio_apiMediaMenuReleasePut({app_id,id});
		// },

		// //保存菜单
		// async saveMenu(){
		// 	let app_id=this.$root.app_id;
		// 	let computer_code=until.getCookie("guid").substring(0, 6);
		// 	let salt=this.salt;
		// 	let name="洁净室管家菜单"
		// 	let contentJson={
		// 		button:[
		// 			{
		// 				name:"洁净管家",
		// 				sub_button:[],
		// 				type:"view",
		// 				url:"https://sziot.kyunmao.com/api_nologin/login/wechat_platform/get_url?app_id=wx0154d46e98975549",
		// 			}
		// 		]
		// 	}
		
		// 	let content=JSON.stringify(contentJson)


		// 	let data=await studio_api.Teaching_studio_apiMediaMenuSavePost({app_id,computer_code,salt,name,content});
		// }


	}
}
</script>
<style scoped>
.home {
	width: 100%;
	background: #F6F6F6;
	height: 100vh;
	display: inline-block;
	overflow: auto;

	box-sizing: border-box;
	padding-bottom: 1.5067rem;
}

.home_head {
	width: 100%;
	height: 240px;
	background: url("/static/imgs/bg/bg_1.png");
	background-size: 100% 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;

	box-sizing: border-box;

	position: relative;
	display: inline-block;

}

.home_main {
	width: 100%;
	padding: 0 30px;
	box-sizing: border-box;
	display: inline-block;


}
.hm_total{
	width: 100%;
	
	background: #FFFFFF;
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	display: inline-block;
	margin-top: 40px;
	padding-bottom: 40px;;
}

.hmt_head{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 24px;
	box-sizing: border-box;
	margin-top: 40px;
	overflow-x: auto;
	overflow-y: hidden;
}
.hmth_div{
	font-size: 32px;
	color: #999999;
	line-height: 45px;
	margin-left: 48px;
	position: relative;
	height: 65px;
	white-space:pre;
}
.hmth_div:first-child{
	margin-left: 0px;
}
.selecthmth{
	font-weight: bold;
	color: #333333;
	line-height: 45px;
}
.hmth_xian{
	width: 100%;
	height: 12px;
	background: #4E89FF;
	border-radius: 6px 6px 6px 6px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0 auto;
}
.hmt_list{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 25px;
	flex-wrap: wrap;
	padding: 0 0 0 9px;
}
.hmtl_div{
	width: 204px;
	height: 180px;
	background: #F8F8F8;
	border-radius: 40px 40px 40px 40px;
	opacity: 1;
	display: inline-block;
	margin-top: 15px;
	margin-left: 15px;
}
.hmtld_top{
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-top: 20px;
	font-size: 48px;
	color: #4E89FF;
	font-weight: bold;
	line-height: 59px;
}
.hmtld_top span:last-child{
	font-size: 24px;
	color: #999999;
	line-height: 40px;
	margin-left: 16px;
}
.hmtld_top .iconfont{
	font-size: 24px;
	color: #4E89FF;
	margin-left: 16px;
}
.hmtld_name{
	width: 100%;
	text-align: center;
	padding: 0 18px;
	box-sizing: border-box;
	font-size: 24px;
	color: #333333;
	line-height: 33px;
	margin-top: 16px;
}
.hm_warning{
	width: 100%;
	display: inline-block;
	
	box-sizing: border-box;
}
.hmw_title{
	width: 100%;
	height: 109px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 32px;
	color: #333333;
	font-weight: bold;
	line-height: 45px;
}
.hmw_list{
	width: 100%;
	display: inline-block;
}
.hmwl_div{
	width: 100%;
	height: 240px;
	background: #FFFFFF;
	border-radius: 20px 20px 20px 20px;
	margin-top: 24px;
	display: inline-block;
	margin-top: 24px;
}
.hmwl_div:first-child{
	margin-top: 0px;
}
.hmwld_top{
	width: 100%;
	padding: 0 24px;
	box-sizing: border-box;
	margin-top: 48px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.hmwldt_left{
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.hl_circle{
	width: 96px;
	height: 96px;
	background: #FFE9E8;
	border-radius: 28px 28px 28px 28px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.hl_circle .iconfont{
	font-size: 50px;
	color: #FF5A4E;
}
.hl_name{
	flex: 1;
	margin-left: 16px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	font-size: 32px;
	font-weight: bold;
	line-height: 45px;
}
.hl_name span:last-child{
	font-size: 28px;
	color: #FF5A4E;
	margin-top: 8px;
	line-height: 40px;
	font-weight: 400;
}
.hmwldt_right{
	width: 112px;
	height: 56px;
	background: #E2ECFF;
	border-radius: 28px 28px 28px 28px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	color: #4E89FF;
}
.hwmld_bottom{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 24px;
	color: #999999;
	line-height: 33px;
	margin-top: 47px;
	padding-left: 24px;
	box-sizing: border-box;
}
.switchTitle {
	font-size: 32px;
	font-weight: 600;
	color: #5AB13E;
	line-height: 45px;
	text-align: center;
	margin-bottom: 80px;
	margin-top: 40px;
}

.switchYes {
	border: 1px solid #79CA6A !important;
}

.switchMain {
	background: #FFFFFF;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
	font-size: 32px;
	font-weight: 400;
	color: #333333;
	line-height: 45px;
	display: flex;
	padding: 30px;
	margin: 40px;
	box-sizing: border-box;

}

.switchAvatar {
	height: 80px;
	width: 80px;
	margin-right: 24px;
}

.switchAvatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.switchLeft {
	float: left;
}

.switchRight {
	float: right;
}

.switchName {
	font-size: 32px;
	font-weight: 400;
	color: #333333;
	line-height: 45px;
}

.switchUserName {
	font-size: 26px;
}
.home_change{
	width: calc(100% - 80px);
	margin-left: 40px;
	padding: 0 24px;
	box-sizing: border-box;
	border-radius: 28px;

	margin-top: 28px;
	background: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 56px;

}
.hc_left{
	font-size: 24px;
	color: #333333;
}
.hc_right{
	display: flex;
	align-items: center;
	justify-content: flex-start;

}
.hc_right .iconfont{
	font-size: 32px;
	color: #4E89FF;
}
.hc_right span{
	font-size: 24px;
	color: #4E89FF;
	margin-left: 8px;
}
.nodata_view{
	width: 100%;
	
	background: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
	flex-direction: column;
}
.nodata_view img{
	width: 560px;
	height: 290px;
}
.nodata_txt{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	color: #333333;
	font-weight: bold;
	margin-top: 48px;
	line-height: 40px;
	margin-bottom: 60px;
}
</style>
